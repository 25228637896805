






































































































import BaseDialog from "../../Base/BaseDialog.vue";
import InputDefault from "../../Input/InputDefault.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseLink from "@/components/Base/BaseLink.vue";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
        InputDefault,
        BaseDialog,
    },
})
export default class SharedProposalHeader extends Vue {
    @Prop() search!: string;
    @Prop() proposalsCount!: number;

    public showSearchDialog: boolean = false;
    public searchValue: string = "";

    get me() {
        return this.$store.state.users.me;
    }

    get isLocked() {
        const isLocked = this.me && !this.me.is_active;

        return isLocked;
    }

    get hasProfile() {
        return this.me && this.me.profile;
    }

    get isDisabled() {
        return this.isLocked || !this.hasProfile;
    }

    searchProposal() {
        this.$emit("update:search", this.searchValue);
        this.showSearchDialog = false;
    }
}
